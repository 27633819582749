<template>
  <div class="home">
    <navigation-bar></navigation-bar>
    <div class="head">
      <h1>{{ $t('home.home1') }}</h1>
      <div class="text">
        {{ $t('home.home2') }}
      </div>
      <img src="@/assets/img/siyEvw 1.png" alt="" />
    </div>
    <div class="two">
      <div class="two2" style="margin-bottom: 0.6rem;">
        <div class="title" style="padding-top: 0.8rem;">SERVICES</div>
        <div class="title2">{{ $t('home.home3') }}</div>
        <div class="title3">
          {{ $t('home.home4') }}
        </div>
      </div>
      <div class="two3">
        <div class="title">{{ $t('home.home5') }}</div>
        <div class="title2">
          {{ $t('home.home6') }}
        </div>
      </div>
      <div class="two3">
        <div class="title">{{ $t('home.home7') }}</div>
        <div class="title2">
          {{ $t('home.home8') }}
        </div>
      </div>
      <div class="two3">
        <div class="title">{{ $t('home.home9') }}</div>
        <div class="title2">
          {{ $t('home.home10') }}
        </div>
      </div>
      <img src="@/assets/img/image 5.png" alt="" />
    </div>
    <div class="three">
      <div class="two2">
        <div class="title">SERVICES</div>
        <div class="title2">{{ $t('home.home11') }}</div>
        <div class="title3">
          {{ $t('home.home12') }}
        </div>
      </div>
      <div class="two3">
        <div class="title">{{ $t('home.home13') }}</div>
        <div class="title2">
          {{ $t('home.home14') }}
        </div>
      </div>
      <div class="two3">
        <div class="title">{{ $t('home.home15') }}</div>
        <div class="title2">
          {{ $t('home.home16') }}
        </div>
      </div>
      <div class="two3">
        <div class="title">{{ $t('home.home17') }}</div>
        <div class="title2">
          {{ $t('home.home18') }}
        </div>
      </div>
      <img src="@/assets/img/image 6.png" alt="" />
    </div>
    <div class="focus">
      <div class="title">{{ $t('home.home19') }}</div>
      <div class="text">
        {{ $t('home.home20') }}
      </div>
    </div>
    <div class="five">
      <div class="item">
        <div class="title">SERVICES</div>
        <h1>{{ $t('home.home21') }}</h1>
        <div class="text">
          {{ $t('home.home22') }}
        </div>
      </div>
      <div class="item">
        <div class="title">SERVICES</div>
        <h1 style="color: #de68f1;">{{ $t('home.home23') }}</h1>
        <div class="text">
          {{ $t('home.home24') }}
        </div>
      </div>
      <div class="item">
        <div class="title">SERVICES</div>
        <h1>{{ $t('home.home25') }}</h1>
        <div class="text">
          {{ $t('home.home26') }}
        </div>
      </div>
      <div class="item">
        <div class="title">SERVICES</div>
        <h1 style="color: #de68f1;">{{ $t('home.home27') }}</h1>
        <div class="text">
          {{ $t('home.home28') }}
        </div>
      </div>
    </div>
    <div class="announcement">
      <div class="announcement-head">{{ $t('home.home34') }}</div>

      <div class="list-title">
        <!-- <div class="time">{{ $t('home.home30') }}</div>
        <div class="name">{{ $t('home.home31') }}</div>
        <div class="type">{{ $t('home.home32') }}</div>
        <div class="click">{{ $t('home.home33') }}</div> -->
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('home.home35')" name="first">
            <div class="consult">
              <div class="position">{{ $t('home.home36') }}</div>
              <div class="position-data">
                <div class="content title-width">
                  <div class="title">{{ $t('home.home37') }}</div>
                  <div class="text">{{ $t('home.home38') }}</div>
                  <!-- <div class="text">{{ $t('home.home39') }}</div> -->
                  <div class="text">{{ $t('home.home40') }}</div>
                  <!-- <div class="text">{{ $t('home.home88') }}</div> -->
                </div>
                <div class="content title-width">
                  <div class="title">{{ $t('home.home89') }}</div>
                  <div class="text">{{ $t('home.home90') }}</div>
                </div>
                <div class="content title-width">
                  <div class="title">{{ $t('home.home91') }}</div>
                  <div class="text">{{ $t('home.home92') }}</div>
                  <div class="text">{{ $t('home.home93') }}</div>
                  <div class="text">{{ $t('home.home94') }}</div>
                </div>
                <div class="content title-width">
                  <div class="title">{{ $t('home.home41') }}</div>
                  <div class="text">{{ $t('home.home42') }}</div>
                  <div class="text">{{ $t('home.home43') }}</div>
                  <div class="text">{{ $t('home.home44') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home45') }}</div>
              <div class="position-data">
                <div class="content title-width">
                  <div class="title">{{ $t('home.home46') }}</div>
                  <div class="text">{{ $t('home.home47') }}</div>
                  <div class="text">{{ $t('home.home48') }}</div>
                  <div class="text">{{ $t('home.home49') }}</div>
                </div>
                <div class="content title-width">
                  <div class="title">{{ $t('home.home50') }}</div>
                  <div class="text">{{ $t('home.home51') }}</div>
                  <div class="text">{{ $t('home.home52') }}</div>
                  <div class="text">{{ $t('home.home53') }}</div>
                </div>
                <div class="content title-width">
                  <div class="title">{{ $t('home.home54') }}</div>
                  <div class="text">{{ $t('home.home55') }}</div>
                  <div class="text">{{ $t('home.home56') }}</div>
                  <div class="text">{{ $t('home.home57') }}</div>
                  <div class="text">{{ $t('home.home58') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home59') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">{{ $t('home.home60') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home61') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">{{ $t('home.home62') }}</div>
                  <div class="text">{{ $t('home.home63') }}</div>
                  <div class="text">{{ $t('home.home87') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home64') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">{{ $t('home.home65') }}</div>
                  <div class="text">{{ $t('home.home66') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home67') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">Cricket Square Hutchins Drive P.O. Box 2681 Grand Cayman KY1-1111 Cayman Islands</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home68') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">{{ $t('home.home69') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home70') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">{{ $t('home.home71') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home72') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">Units A1304–1310, 13 Floor 150 Yeongdeungpo-ro Yeongdeungpo-gu Seoul Korea</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home73') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">{{ $t('home.home74') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home75') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">{{ $t('home.home76') }}</div>
                </div>
              </div>
            </div>
            <div class="consult">
              <div class="position">{{ $t('home.home77') }}</div>
              <div class="position-data">
                <div class="content">
                  <div class="text">8229</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('home.home78')" name="second">
            <div class="policy">
              <div class="item" @click="policyClick($store.state.language === 'zh-CN' ? item.ZH : item.EN)" v-for="(item, index) in policyList" :key="index">
                {{ $store.state.language === 'zh-CN' ? item.ZH : item.EN }}
              </div>
            </div>
            </el-tab-pane>
          <el-tab-pane :label="$t('home.home29')" name="third">
            <div class="list-data">
              <div
                class="data-item"
                v-for="(item, index) in displayedList"
                :key="index"
              >
                <div class="time">{{ item.createTime }}</div>
                <div class="name">{{ item.title }}</div>
                <div class="type">{{ $t('home.home29') }}</div>
                <div
                  class="click"
                  @click="
                    downloadPDF(item.createTime + ' ' + item.title, item.url)
                  "
                >
                  {{ $t('home.home33') }}
                </div>
              </div>
            </div>
            <div class="announcement-pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page="currentPage"
                :page-size="pageSize"
                @current-change="handleCurrentChange"
                :total="
                  $store.state.language === 'zh-CN'
                    ? filesList ? filesList.ZH.length : 0
                    : filesList? filesList.EN.length : 0
                "
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('home.home79')" name="fourth">
            <div class="investor">
              <div class="title">{{ $t('home.home80') }}</div>
              <div class="url">ir@futuredatagroup.com</div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('home.home81')" name="fifth">
            <div class="contactUs">
              <div class="title">{{ $t('home.home82') }}</div>
              <div class="text">{{ $t('home.home83') }}</div>
              <div class="text">{{ $t('home.home84') }}</div>
              <div class="text">{{ $t('home.home85') }}</div>
              <div class="text">{{ $t('home.home86') }}</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavigationBar from '@/components/navigation-bar.vue'
import FooterBar from '@/components/footer.vue'
// import filesList from '@/file/anncmnt.json'
export default {
  name: 'HomeView',
  components: {
    NavigationBar,
    FooterBar,
  },
  data() {
    return {
      activeName: 'first',
      filesList: null,
      dataList: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的数据条数
      policyList: [
        {
          ZH: '組織章程大綱',
          EN: 'MEMORANDUM OF ASSOCIATION',
        },
        {
          ZH: '第二次經修訂及重列組織章程大綱',
          EN: 'SECOND AMENDED AND RESTATED MEMORANDUM OF ASSOCIATION',
        },
        {
          ZH: '發佈企業通訊',
          EN: 'Dissemination of Corporate Communications',
        },
        {
          ZH: '審核委員會職權範圍',
          EN: 'Terms of reference of Audit Committee',
        },
        {
          ZH: '薪酬委員會職權範圍',
          EN: 'Terms of reference of Remuneration Committee',
        },
        {
          ZH: '提名委員會職權範圍',
          EN: 'Terms of reference of Nomination Committee',
        },
      ],
    }
  },
  created() {
    this.loadJsonData()
  },
  mounted() {},
  computed: {
    displayedList() {
      if (this.filesList) {
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        if (this.$store.state.language === 'zh-CN') {
          return this.filesList.ZH.slice(startIndex, endIndex) || [];
        } else {
          return this.filesList.EN.slice(startIndex, endIndex) || [];
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    loadJsonData() {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          this.filesList = JSON.parse(xhr.responseText)
        }
      };
      xhr.open('GET', '/file/anncmnt.json', true); // 根据实际的 JSON 文件路径进行调整
      xhr.send();
    },
    downloadPDF(name, url) {
      const link = document.createElement('a')
      link.href = url
      link.download = name
      link.target = '_blank'
      link.click()
    },
    handleCurrentChange(page) {
      this.currentPage = page
    },
    policyClick(name) {
      const link = document.createElement('a')
      link.href = 'file/' + name + '.pdf'
      // return console.log('file/' + name + '.pdf')
      link.download = name
      link.target = '_blank'
      link.click()
    }
  },
}
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  .head {
    width: 100%;
    height: 6.95rem;
    background: url('@/assets/img/Landing Bar.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.4rem;
    position: relative;
    color: #fff;
    box-sizing: border-box;
    padding-left: 0.4rem;
    padding-top: 2.2rem;
    h1 {
      font-family: Roboto;
      font-size: 60px;
      font-weight: 400;
      line-height: 0.72rem;
      margin-bottom: 0.4rem;
    }
    .text {
      font-family: Roboto;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.24rem;
      width: 8.24rem;
    }
    img {
      width: 2.25rem;
      height: 2.25rem;
      position: absolute;
      top: 2.3rem;
      right: 1.2rem;
    }
  }
  .two2 {
    .title {
      font-family: Roboto;
      font-size: 0.28rem;
      font-weight: 400;
      line-height: 0.6rem;
      color: #56789f;
      margin-bottom: 0.1rem;
    }
    .title2 {
      font-family: Roboto;
      font-size: 0.48rem;
      font-weight: 400;
      line-height: 0.6rem;
      color: #de68f1;
      margin-bottom: 0.1rem;
    }
    .title3 {
      width: 6.39rem;
      font-family: Roboto;
      font-size: 0.18rem;
      font-weight: 400;
      line-height: 0.28rem;
      color: #efefef;
      margin-bottom: 0.4rem;
    }
  }
  .two3 {
    width: 6.39rem;
    margin-bottom: 0.6rem;
    .title {
      font-family: Roboto;
      font-size: 0.28rem;
      font-weight: 400;
      line-height: 0.6rem;
      color: #de68f1;
      margin-bottom: 0.1rem;
    }
    .title2 {
      font-family: Roboto;
      font-size: 0.18rem;
      font-weight: 400;
      line-height: 0.28rem;
      color: #efefef;
      margin-bottom: 0.4rem;
    }
  }
  .two {
    width: 13.6rem;
    height: 12.21rem;
    box-sizing: border-box;
    background: linear-gradient(180deg, #02374f 21.38%, #03192f 100%);
    margin: 0 auto;
    border-radius: 0.12rem;
    margin-bottom: 0.4rem;
    position: relative;
    padding: 0.4rem;

    img {
      width: 6.67rem;
      height: 5.92rem;
      position: absolute;
      top: 2rem;
      right: 0;
    }
  }
  .three {
    width: 13.6rem;
    height: 10.14rem;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    border-radius: 0.12rem;
    background-color: #0a1639;
    padding-left: 6.5rem;
    padding-top: 0.4rem;
    padding-right: 0.6rem;
    margin-bottom: 0.4rem;
    .two2 {
      .title2 {
        color: #68f1a7;
      }
    }
    .two3 {
      .title {
        color: #68f1a7;
      }
    }
    img {
      width: 6.23rem;
      height: 5.3rem;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.12rem 0 0 0;
    }
  }
  .focus {
    width: 13.6rem;
    height: 5.57rem;
    box-sizing: border-box;
    background: url('@/assets/img/image 16.png') no-repeat;
    background-size: 100%;
    margin: 0 auto;
    border-radius: 0.12rem;
    margin-bottom: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-family: Roboto;
      font-size: 0.5rem;
      font-weight: 400;
      line-height: 0.72rem;
      margin-bottom: 0.4rem;
      color: #fff;
    }
    .text {
      font-family: Roboto;
      font-size: 0.2rem;
      font-weight: 400;
      line-height: 0.3rem;
      width: 9.07rem;
      color: #efefef;
    }
  }
  .five {
    width: 13.2rem;
    height: 12.2rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 0.4rem;
    .item {
      width: 6.5rem;
      height: 5.9rem;
      background-color: #07163c;
      box-sizing: border-box;
      border-radius: 0.12rem;
      padding: 0.4rem;
      .title {
        font-family: Roboto;
        font-size: 0.28rem;
        font-weight: 400;
        line-height: 0.6rem;
        margin-bottom: 0.4rem;
        color: #56789f;
      }
      h1 {
        font-family: Roboto;
        font-size: 0.5rem;
        font-weight: 400;
        line-height: 0.7rem;
        color: #68f1a7;
        margin-bottom: 0.4rem;
      }
      .text {
        width: 5.19rem;
        font-family: Roboto;
        font-size: 0.2rem;
        font-weight: 400;
        line-height: 0.3rem;
        color: #efefef;
      }
    }
  }
  .announcement {
    width: 13.6rem;
    box-sizing: border-box;
    background-color: #091a2f;
    margin: 0 auto;
    border-radius: 0.12rem;
    padding-bottom: 0.6rem;
    margin-bottom: 0.4rem;
    .announcement-head {
      width: 100%;
      height: 0.94rem;
      box-sizing: border-box;
      font-family: Roboto;
      display: flex;
      align-items: center;
      font-size: 0.48rem;
      font-weight: 400;
      line-height: 0.6rem;
      color: #8093b0;
      background-color: #15374e;
      padding-left: 0.4rem;
      border-radius: 0.12rem 0.12rem 0 0;
    }
    /deep/ .list-title {
      padding: 0.2rem 0;
      width: 100%;
      // height: 0.76rem;
      box-sizing: border-box;
      background-color: #122f42;
      font-family: Roboto;
      font-size: 0.18rem;
      font-weight: 400;
      line-height: 0.28rem;
      color: #ffffff52;
      // padding-left: 0.2rem;
      // padding-right: 0.4rem;
      // display: flex;
      // align-items: center;
      // .time {
      //   width: 2rem;
      // }
      // .name {
      //   width: 7.5rem;
      // }
      // .type {
      //   width: 1.5rem;
      // }
      // .click {
      // }
      .el-tabs__item {
        font-size: 0.18rem;
        color: #8093b0;
      }
      .el-tabs__item.is-active {
        color: #409eff;
      }
      .el-tabs__nav {
        padding-bottom: 0.1rem;
      }
      .el-tabs__nav-scroll {
        padding-left: 0.2rem;
      }
    }
    .investor {
      padding-left: 0.2rem;
      .title {
        color: #fff;
        margin-bottom: .1rem;
      }
      .url {
        color: #409eff;
      }
    }
    .policy {
      padding-left: 0.2rem;
      .item {
        margin-bottom: .2rem;
        cursor: pointer;
        color: #7340c7;
        text-decoration: underline;
      }
      .item:hover {
          color: #de68f1;
        }
    }
    .contactUs {
      padding-left: 0.2rem;
      .title {
        margin-bottom: .1rem;
        color: #409eff;
      }
      .text {
        margin-bottom: .1rem;
        color: #fff;
      }
    }
    .consult {
      padding-left: 0.2rem;
      margin-bottom: .3rem;
      .position {
        color: #409eff;
        margin-bottom: .1rem;
        font-size: .2rem;
      }
        .position-data {
          display: flex;
          .title-width {
            width: 4rem;
          }
          .content {
            // width: 3rem;
            .title {
              color: #3366cf;
              margin-bottom: .1rem;
            }
            .text {
              font-size: .16rem;
              color: #efefef;
            }
          }
        }
    }
    .list-data {
      width: 100%;
      height: 7.6rem;
      overflow: hidden;
      .data-item:hover {
        // background-color: #252e49;
      }
      .data-item {
        width: 100%;
        height: 0.76rem;
        box-sizing: border-box;
        background-color: #1e3e54;
        display: flex;
        align-items: center;
        .time {
          width: 2rem;
          padding-left: 0.2rem;
          font-family: Roboto;
          font-size: 0.18rem;
          font-weight: 400;
          line-height: 0.28rem;
          color: #efefef;
        }
        .name {
          width: 7.5rem;
          font-family: Roboto;
          font-size: 0.2rem;
          font-weight: 500;
          line-height: 0.6rem;
          color: #de68f1;
          overflow: hidden;
          white-space: nowrap; //内容超出不换行
          text-overflow: ellipsis;
        }
        .type {
          width: 1.5rem;
          font-family: Roboto;
          font-size: 0.18rem;
          font-weight: 400;
          line-height: 0.28rem;
          color: #437cd2;
        }
        .click {
          width: 1.98rem;
          height: 0.42rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          background: linear-gradient(
            272.74deg,
            #7340c7 3.03%,
            #437cd2 101.27%
          );
          font-family: Roboto;
          font-size: 0.18rem;
          font-weight: 400;
          color: #fff;
          border-radius: 0.08rem;
          cursor: pointer;
        }
        .click:hover {
          background: #7340c7;
        }
      }
    }
    .announcement-pagination {
      padding-top: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
