<template>
  <div class="navigation">
    <div class="left"></div>
    <div class="right">
      <el-dropdown @command="handleSetLanguage">
  <span class="el-dropdown-link">
    <span class="language">{{ $t('language.language') }}</span><i class="el-icon-arrow-down el-icon--right"></i>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item
                  :disabled="language === $t('language.zh')"
                  command="zh-CN"
                  ><span>{{ $t('language.zhName') }}</span></el-dropdown-item
                >
    <el-dropdown-item
                  :disabled="language === $t('language.en')"
                  command="en"
                  ><span>{{ $t('language.enName') }}</span></el-dropdown-item
                >
              </el-dropdown-menu>
</el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState(['language']),
  },
  created() {
    console.log(this.language, '1111111')
  },
  methods: {
    ...mapMutations(['remTokenInfo']),
    // 把选择的语言传入vuex
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    },
  }
}
</script>

<style lang="less" scoped>
.navigation {
  width: 100%;
  height: 0.72rem;
  background: #0000003d;
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 99;
  .left {
    font-family: Roboto;
    font-size: .19rem;
    font-weight: 400;
    line-height: .24rem;
    text-align: left;
    color: #fff;
  }
  /deep/ .right {
    cursor: pointer;
    .el-dropdown {
      color: #fff;
      font-size: .19rem;
    font-weight: 400;
    }
  }
}
</style>
