<template>
  <div class="footer">
    FutureData 2024
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .footer {
    width: 100%;
    height: .52rem;
    box-sizing: border-box;
    background: #00000057;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
font-size: .16rem;
font-weight: 400;
color: #fff;
  }
</style>